body {
  margin: 0;
  padding: 0;
}

* {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  /* cursor: url("../public/assets/icons/portfolio/cursor_hover.svg"), pointer;
  cursor: url("../public/assets/icons/portfolio/cursor.svg"), auto; */
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  /* Chrome, Safari, Edge, Opera */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.scrollbar {
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: auto;

  &::-webkit-scrollbar {
    display: block;
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #e9e9e9;
    border-radius: 10px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

.ql-editor {
  padding: 0px !important;
  background-color: white;
}

.ql-editor img {
  max-width: 430px;
}
